// Square Component with prop values
export function Square({isWinning, value, onSquareClick}) {
  const squareClass = `square ${isWinning ? 'winning-square' : ''}`;

  return (
    <button 
      className={squareClass}
      onClick={onSquareClick} // onClick = built-in HTML
                               // onSquareClick = property of a square that stores a function
    >{value}
    </button> 
    )// we need to do something special when we pass a function as a prop
 }