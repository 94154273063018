import { useState } from "react";
import { Board } from './Board';

export default function App() {
  const [squares, setSquares] = useState(Array(9).fill(null));
  const [count, setCount] = useState(0);
  const [gameText, setGameText] = useState("Welcome to aiden-mccormack.com");
  const [winningSquares, setWinningSquares] = useState([]);

  function handleClick(i) {
    if (calculateWinner(squares) || squares[i]) {
      return;
    }
    
    const nextSquares = squares.slice();
    nextSquares[i] = (count % 2) === 0 ? 'X' : 'O';
    setSquares(nextSquares);
    setCount(c => c + 1); // Use functional form for state update based on previous state

    const winner = calculateWinner(nextSquares);
    if (winner) {
      setGameText("Winner: " + nextSquares[i]);
      setWinningSquares(winner);
    } else if (nextSquares.every(square => square != null)) {
      setGameText("Tie!");
    } else {
      setGameText(((count + 1) % 2) === 0 ? 'X to move' : 'O to move');
    }
  }

  // helpfer function to determine winner of the game
  function calculateWinner(squares) {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6]
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return [a, b, c];
      }
    }
    return null;
  }

  function resetGame() {
    setSquares(Array(9).fill(null));
    setCount(0);
    setGameText("Welcome to aiden-mccormack.com");
    setWinningSquares([]);
  }

  return (
    <>
    <div className="container">
    
      <div className="row justify-content-center">
        <p className="game-text">{gameText}</p>
      </div>
      <div class='row justify-content-center'>
        <h3 className="site-subheading">Future site of my programming portfolio. For now, enjoy a game of Tic Tac Toe with a friend!</h3>
      </div>
      <div className="spacer"></div>
      <Board className="game" winners={winningSquares} squares={squares} handleClick={handleClick} />

      <div className="spacer"></div>
      
      <div className="row justify-content-center">
        <button 
          className='btn btn-dark btn-lg'
          type='button'
          onClick={resetGame}
          >RESET</button>
        </div>
    </div>
    </>
  );
}
