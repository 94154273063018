import { Square } from "./Square";

// Board Component

// In the below code, 'handleClick()' gets passed to each square as a property. This means that
// it cannot be accessed directly, thus the arrow function syntax is needed () =>
    // Board.js
export function Board({winners, squares, handleClick }) {

  return (
    <div className="main-grid">
      <div className="container">
        <div className="row justify-content-center">
          <Square className='square col-4' isWinning={winners.includes(0) ? 1 : 0} value={squares[0]} onSquareClick={() => handleClick(0)}/>
          <Square className='square col-4' isWinning={winners.includes(1) ? 1 : 0} value={squares[1]} onSquareClick={() => handleClick(1)}/>
          <Square className='square col-4' isWinning={winners.includes(2) ? 1 : 0} value={squares[2]} onSquareClick={() => handleClick(2)}/>
        </div>
        <div className="row justify-content-center">
          <Square className='square col-4' isWinning={winners.includes(3) ? 1 : 0} value={squares[3]} onSquareClick={() => handleClick(3)}/>
          <Square className='square col-4' isWinning={winners.includes(4) ? 1 : 0} value={squares[4]} onSquareClick={() => handleClick(4)}/>
          <Square className='square col-4' isWinning={winners.includes(5) ? 1 : 0} value={squares[5]} onSquareClick={() => handleClick(5)}/>
        </div>
        <div className="row justify-content-center">
          <Square className='square col-4' isWinning={winners.includes(6) ? 1 : 0} value={squares[6]} onSquareClick={() => handleClick(6)}/>
          <Square className='square col-4' isWinning={winners.includes(7) ? 1 : 0} value={squares[7]} onSquareClick={() => handleClick(7)}/>
          <Square className='square col-4' isWinning={winners.includes(8) ? 1 : 0} value={squares[8]} onSquareClick={() => handleClick(8)}/>
        </div>
      </div>
    </div>
  );
}